import { Button } from '@material-tailwind/react';
import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';
import { ReactComponent as Play } from 'assets/img/btn-play-yellow.svg';
import { useState } from 'react';
import FslightboxReact from "fslightbox-react";

export default function Header() {
    const [index, setIndex] = useState(0)
    const [show, setShow] = useState(false)
    const showVideo = (index) => {
        setIndex(index)
        setShow(!show)
    }
    const videos = [
        ["https://www.youtube.com/watch?v=nnkQgeus4hs"]
    ]

    return (
        <section>
            <div className="container max-w-7xl mx-auto text-center md-hidden" style={{paddingTop: '14rem'}}>
                <div className='text-acto text-56 font-bold' >
                    <div className='flex flex-wrap items-center justify-center' >
                        Con un <LogoPink className='mx-4' style={{width: 133, height: 56}}/> haremos que el 
                    </div>
                    mundo cambie
                </div>
                <div className='text-gray-700 pt-10'>
                    <div className='text-40'>
                        ¿Quienes Somos?
                    </div>
                    <div className='text-2xl pt-10'>
                        Acto es una plataforma digital que ofrece en forma de <br/>
                        subscripción mensual servicios de asistencia y protección.
                    </div>
                </div>
                <div className='mt-18 mx-auto bg-acto-play text-yellow text-lg flex flex-wrap flex-col justify-center items-center cursor-pointer' onClick={() => showVideo(0)} >
                    Mira como funciona
                    <Play />
                </div>
            </div>
            <div className="container max-w-7xl mx-auto text-center lg:hidden" style={{paddingTop: '10.625rem'}}>
                <div className='text-acto font-bold' style={{fontSize: 40}} >
                    <div className='flex flex-wrap items-center justify-center' >
                        Con un <LogoPink className='mx-2' style={{width: 113, height: 47}}/> haremos que el 
                    </div>
                    mundo cambie
                </div>
                <div className='text-gray-700 pt-10'>
                    <div className='text-40'>
                        ¿Quienes Somos?
                    </div>
                    <div className='pt-10' style={{fontSize: 22}}>
                        Acto es una plataforma digital<br/>
                        que ofrece en forma de <br/>
                        subscripción mensual servicios<br/>
                        de asistencia y protección.
                    </div>
                </div>
                <div className="bg-blue-acto" >
                    <div className="pt-2">
                        <div className='mx-auto bg-acto-play text-yellow text-xs flex flex-wrap flex-col justify-center items-center cursor-pointer' onClick={() => showVideo(0)} >
                            Mira como funciona
                            <Play style={{width: 30, height: 30, marginTop: '6px'}}/>
                        </div>
                    </div>
                </div>
                <div className='mt-8' >
                    <a
                        href="https://compras.acto.io/subscription.php"
                        target='_blank'
                        rel="noreferrer"
                    >
                    <Button
                        color="transparent"
                        className="bg-color-acto text-white text-normal text-xl px-20 rounded-4xl mx-auto"
                        ripple="dark"
                        
                    >
                        ¡Protégete Ya!
                    </Button>
                    </a>
                </div>
            </div>
            <FslightboxReact 
                toggler={show}
                sources={videos[index]}
            />
        </section>
    )
}