import { Button } from "@material-tailwind/react"
import S1 from "assets/img/subscriptors/s1.jpg"
import S2 from "assets/img/subscriptors/s2.jpg"
import S3 from "assets/img/subscriptors/s3.jpg"
import S4 from "assets/img/subscriptors/s4.jpg"
import S5 from "assets/img/subscriptors/s5.jpg"
import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';

export default function Suscriptors() {

  const testimonies = [
    {
      photo: S1,
      alt: "Suscriptor one",
      testimony: "Estar protegido nunca había sido tan fácil",
      name: "Ruben Mariscal",
      place: "Ciudad de México"
    },
    {
      photo: S2,
      alt: "Suscriptor two",
      testimony: "Cubri los gastos sin desembolsar",
      name: "Martín Torres",
      place: "Tijuana, BC."
    },
    {
      photo: S3,
      alt: "Suscriptor three",
      testimony: "Conseguí mi membresía mas fácil de lo que pensé",
      name: "Erwin Torres",
      place: "Guadalajara, Jal."
    },
    {
      photo: S4,
      alt: "Suscriptor four",
      testimony: "¡Consultas a domicilio es lo que necesitaba!",
      name: "Karen Torres",
      place: "Tijuana, BC."
    },{
      photo: S5,
      alt: "Suscriptor five",
      testimony: "Me da paz saber que en caso de cualquier accidente tengo mi membresía que me respalda",
      name: "Miriam Gil",
      place: "Ciudad de México"
    }

  ]

  return (
    <>
    <section id="statements" className="bg-suscriptors md-hidden">
          <div className="container max-w-7xl mx-auto px-4">
              <div className="flex flex-wrap pb-20 justify-center items-center" >
                  <div className="text-acto text-center text-56 font-bold">
                    Suscriptores
                  </div>
                  <div className="pl-4">
                    <LogoPink style={{width: 167}} />
                  </div>
              </div>
              <div className="flex flex-wrap justify-center text-center text-2xl">
                <div className="lg:w-2/10 px-6" >
                  <div className="flex flex-wrap justify-center items-center">
                    <img className="rounded-6xl" src={testimonies[0].photo} alt={testimonies[0].alt} />
                    <div className="pt-20">
                      <div className="text-gray-700 text-italic mx-auto" style={{width: "75%"}}>
                        "{testimonies[0].testimony}"
                      </div>
                      <div className="text-xl">
                        {testimonies[0].name}
                      </div>
                      <div className="text-xl">
                        {testimonies[0].place}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/10 px-6" >
                  <div className="flex flex-wrap justify-center items-center">
                    <img className="rounded-6xl" src={testimonies[1].photo} alt={testimonies[1].alt} />
                    <div className="pt-20">
                      <div className="text-gray-700 text-italic mx-auto" style={{width: "75%"}}>
                        "{testimonies[1].testimony}"
                      </div>
                      <div className="text-xl">
                        {testimonies[1].name}
                      </div>
                      <div className="text-xl">
                        {testimonies[1].place}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/10 px-6" >
                  <div className="flex flex-wrap justify-center items-center">
                    <img className="rounded-6xl" src={testimonies[2].photo} alt={testimonies[2].alt} ></img>
                    <div className="pt-20">
                      <div className="text-gray-700 text-italic mx-auto" style={{width: "75%"}}>
                        "{testimonies[2].testimony}"
                      </div>
                      <div className="text-xl">
                        {testimonies[2].name}
                      </div>
                      <div className="text-xl">  
                        {testimonies[2].place}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/10 px-6" >
                  <div className="flex flex-wrap justify-center items-center">
                    <img className="rounded-6xl" src={testimonies[3].photo} alt={testimonies[3].alt} />
                    <div className="pt-20">
                      <div className="text-gray-700 text-italic mx-auto" style={{width: "85%"}}>
                        "{testimonies[3].testimony}"
                      </div>
                      <div className="text-xl">
                        {testimonies[3].name}
                      </div>
                      <div className="text-xl">
                        {testimonies[3].place}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-2/10 px-6" >
                  <div className="flex flex-wrap justify-center items-center">
                    <img className="rounded-6xl" src={testimonies[4].photo} alt={testimonies[4].alt} />
                    <div className="pt-20">
                      <div className="text-gray-700 text-italic mx-auto" style={{width: "75%"}}>
                        "{testimonies[4].testimony}"
                      </div>
                      <div className="text-xl">
                        {testimonies[4].name}
                      </div>
                      <div className="text-xl">
                        {testimonies[4].place}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center items-center text-center pt-20">
                <a
                  href="https://compras.acto.io/subscription.php"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    color="transparent"
                    className="bg-color-acto text-white text-normal text-2xl rounded-2xl px-9"
                    ripple="dark"
                  >
                      Suscríbete
                  </Button>
                </a>
              </div>
          </div>
    </section>
    <section id="statements-mobile" className="lg:hidden" style={{paddingBottom: "15rem", marginTop: 55}}>
          <div className="container max-w-6xl mx-auto px-4">
              <div className="text-acto mx-auto text-center text-3xl font-bold mb-6" >
                  Suscriptores
              </div>
              <div style={{overflowX: "scroll"}} >
                <div className="flex flex-wrap justify-center text-center " style={{width: "54rem"}}>
                  <div className="px-2 sm:w-2/10" >
                    <div className="flex flex-wrap justify-center items-center">
                      <img className="rounded-6xl" src={testimonies[0].photo} alt={testimonies[0].alt} />
                      <div className="px-2 pt-1">
                        <div className="text-sm text-gray-700 text-italic">
                          "{testimonies[0].testimony}"
                        </div>
                        <div className="text-xs">
                          {testimonies[0].name}
                        </div>
                        <div className="text-xs">
                          {testimonies[0].place}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 sm:w-2/10" >
                    <div className="flex flex-wrap justify-center items-center">
                      <img className="rounded-6xl" src={testimonies[1].photo} alt={testimonies[1].alt} />
                      <div className="px-2 pt-1">
                        <div className="text-sm text-gray-700 text-italic">
                          "{testimonies[1].testimony}"
                        </div>
                        <div className="text-xs">
                          {testimonies[1].name}
                        </div>
                        <div className="text-xs">
                          {testimonies[1].place}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 sm:w-2/10" >
                    <div className="flex flex-wrap justify-center items-center">
                      <img className="rounded-6xl" src={testimonies[2].photo} alt={testimonies[2].alt} ></img>
                      <div className="px-2 pt-1">
                        <div className="text-sm text-gray-700 text-italic">
                          "{testimonies[2].testimony}"
                        </div>
                        <div className="text-xs">
                          {testimonies[2].name}
                        </div>
                        <div className="text-xs">
                          {testimonies[2].place}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 sm:w-2/10" >
                    <div className="flex flex-wrap justify-center items-center">
                      <img className="rounded-6xl" src={testimonies[3].photo} alt={testimonies[3].alt} />
                      <div className="px-2 pt-1">
                        <div className="text-sm text-gray-700 text-italic">
                          "{testimonies[3].testimony}"
                        </div>
                        <div className="text-xs">
                          {testimonies[3].name}
                        </div>
                        <div className="text-xs">
                          {testimonies[3].place}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-2 sm:w-2/10" >
                    <div className="flex flex-wrap justify-center items-center">
                      <img className="rounded-6xl" src={testimonies[4].photo} alt={testimonies[4].alt} />
                      <div className="px-2 pt-1">
                        <div className="text-sm text-gray-700 text-italic">
                          "{testimonies[4].testimony}"
                        </div>
                        <div className="text-xs">
                          {testimonies[4].name}
                        </div>
                        <div className="text-xs">
                          {testimonies[4].place}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </section>
    </>
  );
}