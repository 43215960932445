import { Button } from "@material-tailwind/react";
import { ReactComponent as LogoActo } from 'assets/img/mobile/logo_acto.svg';
import LogoMapfre from 'assets/img/mobile/logo_mapfre.png';

export default function Header() {
    const { innerWidth: width, innerHeight: height } = window;
    console.log(width, height)
    
    return (
        <div className="relative pt-10 flex content-center items-center justify-center">
            <div className="container max-w-full relative mx-auto md-hidden">
                <div className="flex flex-wrap bg-free">
                    <div className="w-full lg:w-8/12 px-4 mx-auto text-center">
                        <div className="flex flex-wrap justify-center">
                            <div className="md:w-8/12">
                                <div className="flex flex-col text-center text-white font-bold">
                                    <div className="pt-24 pb-6 text-40">
                                        Tu primer seguro de gastos medicos mayores totalmente digital
                                    </div>
                                    <div className="leading-none-forced lg:text-2xl md:text-2xl pb-4">
                                        Obtenlo desde $299 al mes<br/>
                                        <span style={{fontSize: 15}}>mas IVA</span>
                                    </div>
                                    <div className="mx-auto">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button
                                                color="transparent"
                                                className="px-9 bg-color-acto text-white text-normal text-xl rounded-2xl"
                                                ripple="dark"
                                            >
                                                ¡Protégete Ya!
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container max-w-full relative mx-auto lg:hidden" style={{paddingTop: 56}}>
                <div className="w-full lg:w-8/12 px-4 mx-auto text-center">
                    <div className="flex flex-wrap justify-center text-acto">
                        <div className="md:w-8/12">
                            <div className="flex flex-col text-center font-bold">
                                <LogoActo className="mx-auto" />
                                <div className="pt-4 pb-2 text-2xl">
                                Tu primer seguro de gastos medicos mayores totalmente digital
                                </div>
                                <div className="text-md pb-4 text-blue">
                                    Obtenlo desde $299 al mes<br/>
                                    <span className="text-sm" >mas IVA</span>
                                </div>
                                <div className="mx-auto">
                                    <a
                                        href="https://compras.acto.io/subscription.php"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button
                                            color="transparent"
                                            className="btn-17 bg-color-acto text-white text-normal text-md rounded-4xl"
                                            ripple="dark"
                                        >
                                            ¡Protégete Ya!
                                        </Button>
                                    </a>
                                </div>
                                <div className="flex flex-wrap justify-center items-center text-sm pt-10" >
                                    Respaldados por
                                    <div className="ml-1" style={{width: 102}}>
                                        <img src={LogoMapfre} alt='logo mapfre' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap bg-free" />
            </div>
        </div>
    );
}
