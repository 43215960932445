import { Button } from '@material-tailwind/react';
import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';
import LogoInsurtech from 'assets/img/logo-insurtech.png';
import LogoMapfre from 'assets/img/logo-mapfre.png';
import LogoMexicoAsistencia from 'assets/img/logo-mexico-asistencia.png';

export default function Born() {
    return (
        <section>
            <div className="container max-w-7xl mx-auto md-hidden" style={{paddingTop: '7.375rem'}}>
                <div className='flex flex-wrap' >
                    <div className="w-full lg:w-6/12" style={{marginTop: 90}}>
                        <div className='text-acto text-56 font-bold flex flex-wrap items-center' >
                            ¿Por qué nace <LogoPink className='mx-4' style={{width: 133, height: 56}} />?
                        </div>
                        <div className='text-2xl text-gray-700 leading-10 pt-8' >
                            Acto nace porque lamentablemente el 92% de<br/>
                            los mexicanos no pueden pagarse un seguro<br/>
                            básico y general. Acto nació con la misión de<br/>
                            encontrar una manera más fácil y accesible de<br/>
                            estar asegurados y preparados para cualquier<br/>
                            imprevisto y accidente.
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 text-center text-lg">
                        <div className='bubble-acto-pink mb-4 mx-auto' >
                            Solo <span className='text-white text-2xl' >el 8%</span> de la población de
                            México cuenta con un seguro de
                            gastos médicos mayores.
                        </div>
                        <div className='bubble-acto-purple mb-6 mx-auto' >
                            Lo que nos dice que en caso de un accidente o enfermedad 
                            <span className='text-white text-2xl' >el 92%</span> de la población
                            paga los gastos con su propio
                            dinero
                        </div>
                        <div className='bubble-acto-pink mx-auto' >
                            Segun la OCDE somos el <span className='text-white text-2xl' >2do peor país del MUNDO</span> en gasto de bolsillo en tema de salud con <span className='text-white text-2xl' >41%</span> , la OCDE recomienda el <span className='text-white text-2xl' >9%</span>.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto text-center md-hidden" style={{paddingTop: '9.375rem', marginBottom: '4.375rem'}}>
                <div className='text-acto text-5xl font-bold' style={{lineHeight: '3.75rem'}} >
                    ¿Quién esta detrás?
                </div>
                <div className='text-2xl text-gray-700 leading-10' >
                    Powered by
                </div>
                <div className='flex flex-wrap items-center' >
                    <div className="w-full lg:w-4/12 flex justify-end items-center" >
                        <a
                            href='https://grupoacto.com/'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={LogoInsurtech} style={{width: 216}} alt='insurtech' />
                        </a>
                    </div>
                    <div className="w-full lg:w-4/12" >
                        <img className="mx-auto" src={LogoMapfre} style={{width: 275}} alt='mapfre' />
                    </div>
                    <div className="w-full lg:w-4/12">
                        <a
                            href='https://www.mexicoasistencia.com.mx/'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={LogoMexicoAsistencia} style={{width: 226}} alt='mexico asistencia' />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto lg:hidden" >
                <div className='flex flex-wrap' >
                    <div className="w-full lg:w-6/12 text-center">
                        <div className='text-acto text-4xl font-bold' >
                            ¿Por qué nace
                        </div>
                        <div className='text-acto text-4xl font-bold flex flex-wrap items-center justify-center mt-1' >
                            <LogoPink className='mr-2' style={{width: 110, height: 46}} />?
                        </div>
                        <div className='text-22 text-gray-700 leading-10 pt-8' >
                            Acto nace porque <br/>
                            lamentablemente el 92% de los<br/>
                            mexicanos no pueden pagarse<br/>
                            un seguro básico y general.<br/>
                            Acto nació con la misión de<br/>
                            encontrar una manera más<br/>
                            fácil y accesible de estar<br/>
                            asegurados y preparados para<br/>
                            cualquier imprevisto y <br/>
                            accidente.
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 text-center text-lg mt-10">
                        <div className='bubble-acto-pink mb-4 mx-auto' >
                            Solo <span className='text-white text-2xl' >el 8%</span> de la población de
                            México cuenta con un seguro de
                            gastos médicos mayores.
                        </div>
                        <div className='bubble-acto-purple mb-6 mx-auto' >
                            Lo que nos dice que en caso de un accidente o enfermedad 
                            <span className='text-white text-2xl' >el 92%</span> de la población
                            paga los gastos con su propio
                            dinero
                        </div>
                        <div className='bubble-acto-pink mx-auto' >
                            Segun la OCDE somos el <span className='text-white text-2xl' >2do peor país del MUNDO</span> en gasto de bolsillo en tema de salud con <span className='text-white text-2xl' >41%</span> , la OCDE recomienda el <span className='text-white text-2xl' >9%</span>.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container max-w-7xl mx-auto text-center lg:hidden" style={{paddingTop: '4.25rem'}}>
                <div className='text-acto text-5xl font-bold' style={{lineHeight: '3.75rem'}} >
                    ¿Quién esta detrás?
                </div>
                <div className='text-2xl text-gray-700 leading-10' >
                    Powered by
                </div>
                <div className='flex flex-wrap pt-6' >
                    <div className="w-full lg:w-6/12 flex justify-center items-center">
                        <a
                            href='https://grupoacto.com/'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={LogoInsurtech} style={{width: 151}} alt='insurtech mobile' />
                        </a>
                    </div>
                    <div className="w-full lg:w-6/12 flex justify-center">
                        <a
                            href='https://www.mapfre.com.mx/particulares/'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={LogoMapfre} style={{width: 195}} alt='mapfre mobile' />
                        </a>
                    </div>
                    <div className="w-full lg:w-6/12 flex justify-center" style={{marginTop: '-3rem', marginBottom: '-1rem'}}>
                        <a
                            href='https://www.mexicoasistencia.com.mx/'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={LogoMexicoAsistencia} style={{width: 156}} alt='mexico asistencia mobile' />
                        </a>
                    </div>
                </div>
                <div className="my-8">
                    <a
                        href="https://compras.acto.io/subscription.php"
                        target='_blank'
                        rel="noreferrer"
                    >
                    <Button
                        color="transparent"
                        className="bg-color-acto text-white text-normal text-xl px-20 rounded-4xl mx-auto"
                        ripple="dark"
                        
                    >
                        ¡Protégete Ya!
                    </Button>
                    </a>
                </div>
            </div>
        </section>
    )
}