import { Button } from "@material-tailwind/react";

export default function ProtectionSection() {
  return (
    <>
      <section className="bg-benefits-protections md-hidden">
          <div className="container max-w-7xl mx-auto px-2">
              <div className="flex flex-wrap pt-10 lg:text-2xl md:text-xl">
                <div className="w-full md:w-8/12" >
                </div>
                <div className="w-full md:w-4/12 pr-2" >
                  <div className="text-pink lg:text-5xl md:text-4xl font-bold mb-8" >
                    Protecciones
                  </div>
                  <div className="pb-4">
                    <span className="text-pink font-bold" >
                      Efectivo por accidente
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Recibes hasta $50 mil pesos para tus gastos médicos que llegues a necesitar en caso de un accidente.<br/>
                      <span className="lg:text-22" >Sin deducible ni coaseguro.</span>
                    </span>
                  </div>
                  <div className="pb-4" >
                    <span className="text-pink font-bold" >
                      Efectivo por cáncer
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Si te llegarán a diagnosticar cáncer tendrás $150 mil pesos para hacerle frente.
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-pink font-bold" >
                      Efectivo por muerte accidental
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Si llegaras a fallecer , tu beneficiario recibe $200 mil pesos<br/>
                      <span className="lg:text-22" >Sin deducible ni coaseguro.</span>
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-pink font-bold" >
                      Efectivo por fractura
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Recibe hasta $40,000 pesos en caso de una fractura.<br/>
                      <span className="lg:text-22" >Sin deducible ni coaseguro.</span>
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-pink font-bold" >
                      Efectivo por infarto al miocardio
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      $100,000 pesos si llegarás a tener un infarto al miocardio.
                      <br/>
                    </span>
                  </div>
                  <div className="pb-4">
                    <span className="text-pink font-bold" >
                      Efectivo por enfermedad vascular cerebral
                    </span><br/>
                    <span className="text-gray-500 lg:text-2xl">
                      Recibe $50,000 pesos si llegas a contraer una enfermedad vascular cerebral. 
                      <br/>
                    </span>
                  </div>
                  <div >
                      <a
                          href="https://compras.acto.io/subscription.php"
                          target='_blank'
                          rel="noreferrer"
                      >
                      <Button
                          color="transparent"
                          className="bg-color-acto text-white text-normal text-2xl px-20 py-4 rounded-4xl"
                          ripple="dark"
                          
                      >
                          ¡Protégete Ya!
                      </Button>
                      </a>
                  </div>
                </div>
              </div>
          </div>
      </section>
      <section className="lg:hidden">
          <div className="container w-full">
              <div className="flex flex-wrap">
                <div className="w-full bg-benefits-protections" />
                <div className="w-full text-center" >
                  <div className="text-pink text-3xl font-bold mb-8 mt-2" >
                    Protecciones
                  </div>
                  <div className="pb-6">
                    <span className="text-pink font-bold text-lg" >
                      Efectivo por accidente
                    </span><br/>
                    <span className="text-gray-500">
                      Recibes hasta $50 mil pesos para tus<br/>
                      gastos médicos que llegues a necesitar<br/>
                      en caso de un accidente.<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                  <div className="pb-6" >
                    <span className="text-pink font-bold text-lg" >
                      Efectivo por cáncer
                    </span><br/>
                    <span className="text-gray-500">
                      Si te llegarán a diagnosticar cáncer<br/>
                      tendrás $150 mil pesos<br/>
                      para hacerle frente.<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                  <div className="pb-6">
                    <span className="text-pink font-bold" >Efectivo por muerte accidental</span><br/>
                    <span className="text-gray-500">
                      Si llegaras a fallecer , tu beneficiario<br/>
                      recibe $200 mil pesos<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                  <div className="pb-6">
                    <span className="text-pink font-bold" >
                      Efectivo por fractura
                    </span><br/>
                    <span className="text-gray-500">
                      Recibe hasta $40,000 pesos en<br/> 
                      caso de una fractura<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                  <div className="pb-6">
                    <span className="text-pink font-bold" >
                      Efectivo por infarto al miocardio
                    </span><br/>
                    <span className="text-gray-500">
                      Recibe $100,000 pesos si llegarás a tener<br/>
                      un infarto al miocardio.<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                  <div className="pb-6">
                    <span className="text-pink font-bold" >
                      Efectivo por enfermedad vascular<br/>cerebral
                    </span><br/>
                    <span className="text-gray-500">
                      Recibe $50,000 pesos si llegas<br/>
                      a contraer una enfermedad<br/>
                      vascular cerebral.<br/>
                      <span className="text-xs" >
                        Sin deducible ni coaseguro.
                      </span>
                    </span>
                  </div>
                </div>
                <div className="mx-auto mt-6 mb-10">
                  <a
                      href="https://compras.acto.io/subscription.php"
                      target='_blank'
                      rel="noreferrer"
                    >
                      <Button
                          color="transparent"
                          className="bg-color-acto text-white text-normal text-xl px-20 rounded-4xl mx-auto"
                          ripple="dark"
                          
                      >
                          ¡Protégete Ya!
                      </Button>
                    </a>
                </div>
              </div>
          </div>
      </section>
    </>
  );
}
