import Carousel from 'react-material-ui-carousel';
import Hiking from 'assets/img/slider/hiking.png'
import Doctor from 'assets/img/slider/doctor.png'
// import Thief from 'assets/img/slider/thief.png'
import Car from 'assets/img/slider/car.png'
import Family from 'assets/img/slider/family.png'
import Hurt from 'assets/img/slider/broken.png'
import { ReactComponent as LogoPink } from 'assets/img/acto-pink.svg';
import { ReactComponent as LogoGray } from 'assets/img/acto-gray.svg';
import { Button } from '@material-tailwind/react';


export default function SliderSection() {
    const IconComponent = () => <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-aq8pb7-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FiberManualRecordIcon"><circle cx={12} cy={12} r={10} ></circle></svg>;
    return (
        <>
            <section className="relative block bg-white md-hidden carousel">
                <div className="container max-w-7xl mx-auto px-4" >
                    <Carousel
                        fullHeightHover
                        navButtonsAlwaysInvisible
                        interval={8000}
                        indicatorIconButtonProps={{
                            style: {
                                color: "white",
                                backgroundColor: 'rgb(0,0,0,0.1)',
                                margin: 3
                            },
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                color: "rgb(69,16,138)",
                                backgroundColor: 'rgb(0,0,0,0.1)',
                            }
                        }}
                        height={700}
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                background: 'linear-gradient(90deg, rgba(69,16,138,1) 0%, rgba(52,10,106,1) 10%, rgba(69,16,138,1) 100%)',
                                borderRadius: 0,
                                color: 'white',
                                width: '100px',
                                height: '80px',
                            }
                        }}
                    >
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-4/12 flex flex-wrap items-center" >
                                <div className='text-left' style={{marginTop: '-6rem', marginRight: '-3rem'}} >
                                    <div className="text-acto font-bold text-5xl py-4">
                                        No te detengas,
                                        <div className='flex flex-wrap pt-2'>
                                            <div className='pr-4'>
                                                <LogoPink style={{width: 138, height: 58}} />
                                            </div>
                                            <div>te protege</div>
                                        </div>
                                    </div>
                                    <div className="text-gray-700 text-2xl py-4" >
                                        Con tu membresía puedes estar <br/>
                                        tranquilo y vivir <br/>
                                        haciendo lo que más te gusta, <br/>
                                        nosotros te protegemos.
                                    </div>
                                    <div className="pt-8">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                        <Button
                                            color="transparent"
                                            className="bg-color-acto text-white text-normal text-2xl px-20 rounded-4xl"
                                            ripple="dark"
                                            
                                        >
                                            ¡Protégete Ya!
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-8/12" >
                                <img src={Hiking} alt="hiking" className='mx-auto'/>
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider">
                            <div className="w-full md:w-4/12 flex flex-wrap items-center"  >
                                <div className="text-left" style={{marginTop: '-6rem'}} >
                                    <div className="text-acto font-bold text-5xl py-4">
                                        Solicita efectivo <br/>
                                        en caso de un <br/>
                                        imprevisto médico
                                    </div>
                                    <div className="text-gray-700 text-2xl py-4" >
                                        <div className='flex flex-wrap items-center'>
                                            <div>La membresía</div>
                                            <div className='px-2'>
                                                <LogoGray style={{width: 60, height: 26}} />
                                            </div>
                                            <div>te</div>
                                        </div>
                                        entrega dinero en efectivo en<br/>
                                        caso de enfermedad<br/>
                                        Enfermedad vascular cerebral,<br/>
                                        cáncer e Infarto al miocardio.
                                    </div>
                                    <div className="pt-8">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                        <Button
                                            color="transparent"
                                            className="bg-color-acto text-white text-normal text-2xl px-20 rounded-4xl"
                                            ripple="dark"
                                            
                                        >
                                            ¡Protégete Ya!
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-8/12" >
                                <img src={Doctor} alt="doctor" className='mx-auto' />
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider">
                            <div className="w-full md:w-4/12 flex flex-wrap items-center" >
                                <div className="text-left" style={{marginTop: '-6rem'}} >
                                    <div className="text-acto font-bold text-5xl py-4">
                                        Protege a tus<br/>beneficiarios
                                    </div>
                                    <div className="text-gray-700 text-2xl py-4" >
                                        <div className='flex flex-wrap items-center'>
                                            <div>La membresía</div>
                                            <div className='px-2'>
                                                <LogoGray style={{width: 60, height: 26}} />
                                            </div>
                                            <div>te</div>
                                        </div>
                                        a tus beneficiarios con <br/>
                                        $250,000 en caso de muerte <br/>
                                        accidental.
                                    </div>
                                    <div className="pt-8">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                        <Button
                                            color="transparent"
                                            className="bg-color-acto text-white text-normal text-2xl px-20 rounded-4xl"
                                            ripple="dark"
                                            
                                        >
                                            ¡Protégete Ya!
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-8/12" >
                                <img src={Family} alt="family" className='mx-auto' style={{width: "94%"}} />
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider">
                            <div className="w-full md:w-4/12 flex flex-wrap items-center " >
                                <div className="text-left" style={{marginTop: '-6rem'}}>
                                    <div className="text-acto font-bold text-5xl py-4">
                                        Asistencias a <br/>tu disposición
                                    </div>
                                    <div className="text-gray-700 text-2xl py-4" >
                                        <div className='flex flex-wrap items-center'>
                                            <div>La membresía</div>
                                            <div className='px-2'>
                                                <LogoGray style={{width: 60, height: 26}} />
                                            </div>
                                            <div>te envía</div>
                                        </div>
                                        ayuda en caso de necesitar un <br/>
                                        médico, grúa, ambulancia o <br/>
                                        asitencia vial.
                                    </div>
                                    <div className="pt-8">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                        <Button
                                            color="transparent"
                                            className="bg-color-acto text-white text-normal text-2xl px-20 rounded-4xl"
                                            ripple="dark"
                                            
                                        >
                                            ¡Protégete Ya!
                                        </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-8/12" >
                                <img src={Car} alt="car" className='mx-auto' />
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider">
                            <div className="w-full md:w-4/12 flex flex-wrap items-center" >
                                <div className="text-left" style={{marginRight: "-3rem", marginTop: "-7rem"}}>
                                    <div className="text-acto font-bold text-5xl py-4">
                                        Dinero en efectivo<br/>cuando más lo<br/>necesitas
                                    </div>
                                    <div className="text-gray-700 text-2xl py-4" >
                                        <div className='flex flex-wrap items-center'>
                                            <div>La membresía</div>
                                            <div className='px-2'>
                                                <LogoGray style={{width: 60, height: 26}} />
                                            </div>
                                            <div>te entrega</div>
                                        </div>
                                        dinero en efectivo en caso de <br/>
                                        fractura.
                                    </div>
                                    <div className="pt-8">
                                        <a
                                            href="https://compras.acto.io/subscription.php"
                                            target='_blank'
                                            rel="noreferrer"
                                        >
                                            <Button
                                                color="transparent"
                                                className="bg-color-acto text-white text-normal text-2xl px-20 rounded-4xl"
                                                ripple="dark"
                                                
                                            >
                                                ¡Protégete Ya!
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-8/12" >
                                <img src={Hurt} alt="hurt" className='mx-auto' />
                            </div>
                        </div>
                    </Carousel>
                </div>
                <div className="container max-w-7xl mx-auto px-4 pt-24" >
                    <div className='flex flex-wrap justify-center text-acto text-7xl font-bold items-center'>
                        ¿Qué incluye <LogoPink className='px-4' style={{width: 208}} />?
                    </div>
                </div>
            </section>
            <section className="relative block bg-white lg:hidden" style={{paddingTop: '9rem'}} >
                <div className="container w-full mx-auto">
                    <Carousel
                        fullHeightHover
                        navButtonsAlwaysInvisible
                        indicatorIconButtonProps={{
                            style: {
                                color: "white",
                                backgroundColor: 'rgb(0,0,0,0.1)',
                                margin: 3
                            },
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                color: "rgb(69,16,138)",
                                backgroundColor: 'rgb(0,0,0,0.1)',
                            }
                        }}
                        interval={80000}
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                background: 'linear-gradient(90deg, rgba(69,16,138,1) 0%, rgba(52,10,106,1) 10%, rgba(69,16,138,1) 100%)',
                                borderRadius: 0,
                                color: 'white',
                                width: '60px',
                                height: '60px'
                            }
                        }}
                        height={465}
                    >
                        <div className="flex flex-wrap height-slider bg-hiking">
                            <div className='mx-auto text-center' style={{width: "80%", marginTop: "-0.5rem"}} >
                                <div className="flex flex-col">
                                    <div className="text-acto font-bold text-lg">
                                        No te detengas,<br/>ACTO te protege
                                    </div>
                                    <div className="text-gray-700 text-xs" >
                                        Con tu membresía puedes estar tranquilo y vivir <br/>
                                        haciendo lo que más te gusta, nosotros te <br/>
                                        protegemos.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider bg-doctor">
                            <div className='mx-auto text-center' style={{width: "80%", marginTop: "-2rem"}} >
                                <div className="flex flex-col">
                                    <div className="text-acto font-bold text-lg leading-none pb-2">
                                        Solicita efectivo<br/>
                                        en caso de un<br/>imprevisto médico
                                    </div>
                                    <div className="text-gray-700 text-xs" >
                                        La membresía ACTO te entrega dinero en efectivo<br/>
                                        en caso de enfermedad grave o diagnóstico<br/>
                                        médico.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider bg-family">
                            <div className='mx-auto text-center' style={{width: "80%", marginTop: "-1.5rem"}} >
                                <div className="flex flex-col">
                                    <div className="text-acto font-bold text-lg">
                                        Protege a tus beneficiarios
                                    </div>
                                    <div className="text-gray-700 text-xs" >
                                        La membresía ACTO protege a tus beneficiarios<br/>
                                        con $250,000 en caso de muerte accidental.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider bg-car">
                            <div className='mx-auto text-center' style={{width: "80%", marginTop: "-2.5rem"}} >
                                <div className="flex flex-col">
                                    <div className="text-acto font-bold text-lg leading-none">
                                        Asistencias a <br/>tu disposición
                                    </div>
                                    <div className="text-gray-700 text-xs" >
                                        La membresia ACTO te envía ayuda en caso<br/>
                                        de necesitar un médico, grúa, ambulancia o<br/>
                                        asitencia vial.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap height-slider bg-hurt">
                            <div className='mx-auto text-center' style={{width: "80%", marginTop: "-1.125rem"}} >
                                <div className="flex flex-col mr-2">
                                    <div className="text-acto font-bold text-lg leading-none pb-4">
                                        Dinero en efectivo<br/>cuando más lo necesitas
                                    </div>
                                    <div className="text-gray-700 text-xs" >
                                        La membresía ACTO te entrega<br/>
                                        dinero en efectivo y te asiste<br/>
                                        cuando más lo necesitas
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <a
                        href="https://compras.acto.io/subscription.php"
                        target='_blank'
                        rel="noreferrer"
                    >
                        <Button
                            color="transparent"
                            className="bg-color-acto text-white text-normal text-xl px-20 rounded-4xl mx-auto mt-6"
                            ripple="dark"
                            
                        >
                            ¡Protégete Ya!
                        </Button>
                    </a>
                </div>
                <div className="container max-w-7xl mx-auto px-4 pt-10" >
                    <div className='text-acto mx-auto text-lg text-center font-bold'>
                        Para que tu ritmo de vida no se <br/>
                        detenga, ACTO esta ahí cuando<br/>
                        lo necesites, para brindarte<br/>
                        asistencia o darte dinero en <br/>
                        efectivo, a un click de distancia.
                    </div>
                </div>
                <div className="container max-w-7xl mx-auto px-4 pt-8" >
                    <div className='flex flex-wrap justify-center text-acto text-3xl font-bold items-center'>
                        ¿Qué incluye <LogoPink className='mx-2' style={{width: 91, height: 38}} />?
                    </div>
                </div>
            </section>
        </>
    );
}
